import { zIndex } from '../../../config';
import { MeetingLayoutSpec } from '../../../interfaces';
import { MDGlobalSpec } from './MDGlobal';

export const MDPortraitNormalSpec: MeetingLayoutSpec = {
  thumbnailParticipant: { width: 160, height: 90 },
  thumbnailSelf: { width: 100, height: 57 },
  thumbnailRadius: 5,
  thumbnailSpacing: 5,
  get videosWrapper() {
    return {
      height: this.thumbnailParticipant.height + this.thumbnailSpacing * 8
    };
  },

  get contentArea() {
    return {
      top: MDGlobalSpec.logoSectionHeight,
      bottom: this.videosWrapper.height
    };
  }
};

const {
  thumbnailParticipant,
  thumbnailRadius,
  thumbnailSpacing,
  videosWrapper,
  contentArea,
  thumbnailSelf
} = MDPortraitNormalSpec;

const presentationParticipantSize =
  thumbnailParticipant.height / 2 - thumbnailSpacing;

const MDPortraitNormal = () => (
  <>
    <style jsx global>{`
      .normal-mode.MDPortrait .video-thumbnail {
        border-radius: ${thumbnailRadius}px;
        margin: ${thumbnailSpacing}px;
      }

      .normal-mode.MDPortrait .video-thumbnail video {
        border-radius: ${thumbnailRadius}px;
      }

      .normal-mode.MDPortrait #videos-wrapper {
        position: fixed;
        left: 0;
        right: 0;
        top: auto;
        overflow: auto;
        bottom: 0;
        height: ${videosWrapper.height}px;
        display: flex;
        align-items: center;
      }

      .normal-mode.MDPortrait #videos-wrapper .video-thumbnail {
        width: ${thumbnailParticipant.width}px;
        height: ${thumbnailParticipant.height}px;
      }

      .normal-mode.MDPortrait .scroll-container {
        white-space: nowrap;
        display: block;
        font-size: 0;
        margin: auto;
      }

      .normal-mode.MDPortrait #videos-wrapper > .scroll-container {
        padding: 0 ${MDPortraitNormalSpec.thumbnailSpacing * 4}px;
      }
      .normal-mode.MDPortrait #local-wrapper .local-video-container {
        width: ${thumbnailSelf.width}px;
        height: ${thumbnailSelf.height}px;
        margin: 0;
      }
      .normal-mode.MDPortrait #local-wrapper {
        position: fixed;
        top: ${contentArea.top + thumbnailSpacing}px;
        left: ${thumbnailSpacing}px;
        width: ${thumbnailSelf.width}px;
        height: ${thumbnailSelf.height}px;
        z-index: ${zIndex.meetingVideosWrapper};
        margin: ${thumbnailSpacing}px;
      }

      .normal-mode.MDPortrait.has-popup #local-wrapper {
        pointer-events: none;
        opacity: 0.01;
      }

      .normal-mode.MDPortrait .vb-page-wrapper,
      .normal-mode.MDPortrait .popup-page,
      .normal-mode.MDPortrait .popup-page-content,
      .normal-mode.MDPortrait .SBDocumentViewer {
        bottom: ${contentArea.bottom}px !important;
        top: ${contentArea.top}px !important;
        left: 0 !important;
        right: 0 !important;
      }
      .normal-mode.MDPortrait .SBDocumentViewer {
        width: 100% !important;
        height: calc(
          100% - ${(contentArea.top || 0) + (contentArea.bottom || 0)}px
        ) !important;
      }
      .normal-mode.canControl.MDPortrait:not(.no-controls) .SBDocumentViewer,
      .normal-mode.document-controller.MDPortrait:not(.no-controls)
        .DocumentViewer {
        bottom: ${contentArea.bottom + 50}px !important;
      }

      .normal-mode.MDPortrait .psExperience .popup-page-content {
        left: auto !important;
        width: 50vw !important;
      }

      .normal-mode.MDPortrait .enlarge-video .video-thumbnail {
        top: ${contentArea.top}px !important;
        bottom: ${contentArea.bottom}px !important;
      }

      .normal-mode.MDPortrait .meeting-controls {
        bottom: ${videosWrapper.height + 10}px;
      }

      .normal-mode.MDPortrait .video-wrapper-bg {
        top: auto;
        height: ${videosWrapper.height}px;
      }

      .has-presenter.normal-mode.MDPortrait
        #participant-wrapper
        .video-thumbnail {
        width: ${presentationParticipantSize}px;
        height: ${presentationParticipantSize}px;
        margin-top: 0;
        margin-bottom: 0;
      }

      .has-presenter.normal-mode.MDPortrait #participant-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        writing-mode: vertical-lr;
        height: ${thumbnailParticipant.height}px;
        justify-content: space-between;
      }
      .has-presenter.normal-mode.MDPortrait #participant-wrapper .display-name {
        display: none;
      }

      .has-presenter.normal-mode.MDPortrait #presenter-wrapper {
        display: flex;
      }
      .has-presenter.normal-mode.MDPortrait
        #videos-wrapper
        > .scroll-container {
        display: flex;
        align-items: center;
      }

      .normal-mode.MDPortrait .one-third-wrapper {
        top: ${contentArea.top}px !important;
        left: ${contentArea.left || 0}px !important;
        bottom: ${contentArea.bottom}px !important;
      }
      .normal-mode.MDPortrait .video-content-area {
        top: ${contentArea.top}px !important;
        bottom: ${contentArea.bottom}px !important;
      }
      .normal-mode.MDPortrait {
        --onethird-left: calc(0.3333 * 100vw);
      }

      .normal-mode.MDPortrait.one-third .SBDocumentViewer {
        width: unset !important;
      }
      /* END: NORMAL MODE MEDIUM SCREEN PORTRAIT */
    `}</style>
  </>
);
export default MDPortraitNormal;
