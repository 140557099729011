import React from 'react';
import { assetBaseUrl } from '../../../../config';
import { getViewportSize } from '../../../../utils/window';
import { selectEl } from './animationUtils';

const chars = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z'
];

function getCharSet(char: string, length: number) {
  const myIndex = chars.indexOf(char);
  const copy = [...chars];

  if (myIndex < 0) {
    copy[copy.length] = char;
  }

  const charsCount = copy.length;

  return [...copy, ...copy, ...copy].slice(
    myIndex + charsCount * 2 - length - 2,
    myIndex + charsCount * 2 + 1
  );
}

export enum FlipBoardTextTheme {
  RED = 'RED',
  WHITE = 'WHITE',
  BLACK = 'BLACK'
}

const LabelBox = ({
  char,
  index,
  letterBoxSize = 40,
  speed = 100,
  theme = FlipBoardTextTheme.RED,
  onFlipFinish
}: {
  char: string;
  index: number;
  letterBoxSize?: number;
  speed?: number;
  theme?: FlipBoardTextTheme;
  onFlipFinish?: () => void;
}) => {
  const [letter, setLetter] = React.useState({ current: '', flipTo: '' });
  const getThemeStyle = (): {
    textColor: string;
    backgroundImage: string;
    borderDarkColor: string;
    borderLightColor: string;
  } => {
    if (theme === FlipBoardTextTheme.BLACK) {
      return {
        backgroundImage: `${assetBaseUrl}/loubiairways/luggage-animation/flipboard/v2/flipboard_black.png`,
        textColor: '#fff',
        borderDarkColor: '#000',
        borderLightColor: 'rgba(255, 255, 255, 0.3)'
      };
    }

    if (theme === FlipBoardTextTheme.WHITE) {
      return {
        backgroundImage: `${assetBaseUrl}/loubiairways/luggage-animation/flipboard/v2/flipboard_white.png`,
        textColor: '#CE0E2D',
        borderDarkColor: 'rgba(211, 211, 211, 0.6)',
        borderLightColor: 'rgba(255, 255, 255, 0.4)'
      };
    }
    return {
      backgroundImage: `${assetBaseUrl}/loubiairways/luggage-animation/flipboard/v2/flipboard_red.png`,
      textColor: '#fff',
      borderDarkColor: '#a9172f',
      borderLightColor: 'rgba(255, 255, 255, 0.2)'
    };
  };
  React.useEffect(() => {
    let i = 0;
    const chars = getCharSet(char, index);
    let current = '';
    let flipTo = '';
    const interval = setInterval(() => {
      if (i < chars.length) {
        current = chars[i];
        flipTo = chars[i + 1];
        i++;
      } else {
        flipTo = '';
        clearInterval(interval);
        if (onFlipFinish) {
          onFlipFinish();
        }
      }
      setLetter({ current, flipTo });
    }, speed);
  }, []);

  return (
    <div className="letter-box">
      <div className="letter">{letter.current}</div>
      {letter.flipTo && <div className="flipTo">{letter.flipTo}</div>}
      <div className="line" />
      <style jsx>{`
        .letter-box {
          background-image: url('${getThemeStyle().backgroundImage}');
          background-size: 100% auto;
          background-repeat: no-repeat;
          display: inline-block;
          font-size: ${letterBoxSize * 0.85}px;
          font-family: 'Antique-Olive', Arial;
          text-transform: uppercase;
          position: relative;
          vertical-align: top;
          color: ${getThemeStyle().textColor};
          width: ${letterBoxSize}px;
          height: ${letterBoxSize * 1.275}px;
          line-height: ${letterBoxSize * 1.3}px;
          text-align: center;
          margin: ${letterBoxSize * 0.2}px ${letterBoxSize * 0.05}px;
        }
        .letter {
          width: ${letterBoxSize * 0.7}px;
          margin: auto;
        }
        .flipTo {
          width: ${letterBoxSize}px;
          position: absolute;
          background-image: url('${getThemeStyle().backgroundImage}');
          background-size: 100% auto;
          background-repeat: no-repeat;
          height: ${letterBoxSize * 1.275}px;
          border-radius: 3px;
          border-bottom: 2px solid ${getThemeStyle().borderDarkColor};
          text-align: center;
          left: 0px;
          top: 0;
          -webkit-animation: scale-in-top ${speed / 1000}s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
          animation: scale-in-top ${speed / 1000}s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
        }

        @-webkit-keyframes scale-in-top {
          0% {
            -webkit-transform: scaleY(0);
            transform: scaleY(0);
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            opacity: 1;
          }
          100% {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            opacity: 1;
          }
        }
        @keyframes scale-in-top {
          0% {
            -webkit-transform: scaleY(0);
            transform: scaleY(0);
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            opacity: 1;
          }
          100% {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            opacity: 1;
          }
        }

        .line {
          height: 0;
          width: ${letterBoxSize * 0.7}px;
          left: ${letterBoxSize * 0.15}px;
          position: absolute;
          top: ${letterBoxSize * 0.6}px;
          border-top: 1px solid ${getThemeStyle().borderDarkColor};
          border-bottom: 1px solid ${getThemeStyle().borderLightColor};
        }
      `}</style>
    </div>
  );
};

const FlipBoardText = ({
  text,
  theme,
  noFadeOut
}: {
  text?: string;
  theme?: FlipBoardTextTheme;
  noFadeOut?: boolean;
}) => {
  if (!text) return null;

  const [letterBoxSize, setLetterBoxSize] = React.useState(25);

  const getViewPortCategory = () => {
    const { vw } = getViewportSize();
    if (vw > 768 && vw <= 1024) {
      setLetterBoxSize(30);
    }
    if (vw > 1024) {
      setLetterBoxSize(35);
    }
    if (vw <= 768) {
      setLetterBoxSize(25);
    }
  };

  const ref = React.useRef<HTMLDivElement>();

  const onFlipFinish = () => {
    if (!noFadeOut) {
      selectEl(ref).addClass('fade-out', 4000);
    }
  };

  React.useEffect(() => {
    getViewPortCategory();
    window.onresize = getViewPortCategory;
  }, []);
  const words = text.split(' ');
  let index = 0;
  const lastIndex = words.join('').length - 1;
  return (
    <div ref={ref} className="FlipBoardText">
      {words?.map((word, i) => (
        <span className="word" key={word + i}>
          <span className="space" />
          {word.split('').map((letter) => {
            index++;
            return (
              <LabelBox
                letterBoxSize={letterBoxSize}
                char={letter}
                index={index}
                key={Math.random()}
                theme={theme}
                onFlipFinish={index === lastIndex ? onFlipFinish : undefined}
              />
            );
          })}
          <span className="space" />
        </span>
      ))}
      <style jsx>{`
        .FlipBoardText {
          font-size: 0;
          line-height: 1;
        }
        .FlipBoardText.fade-out {
          animation: fade-out 0.3s linear both;
        }
        .word-wrapper {
          backround: green;
        }
        span {
          display: inline-block;
        }
        .word {
          font-size: 0;
        }
        .space {
          width: ${letterBoxSize / 4}px;
          height: 10px;
        }
        .space:first-child {
          margin-right: ${letterBoxSize * 0.05}px;
        }
        .space:last-child {
          margin-left: ${letterBoxSize * 0.05}px;
        }
        @keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default React.memo(FlipBoardText);
