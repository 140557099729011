import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import LoubiButtonWrapper from './LoubiButtonWrapper';

const BackToAnimationButton = ({
  onClick,
  position,
  imageUrl
}: {
  onClick: () => void;
  position?: string;
  imageUrl?: string;
}) => {
  const { activeAnimation } = useLoubiAirwaysState();

  if (!activeAnimation) return null;
  return (
    <LoubiButtonWrapper>
      <img
        src={imageUrl || "/asset/louboutin/back_to_animation.png"}
        alt="skip"
        onClick={onClick}
        className={`loubi-circle-button ${position || 'top-right'}`}
      />
    </LoubiButtonWrapper>
  );
};

export default BackToAnimationButton;
