import {
  LoubiAirwaysAnimation,
  LoubiAirwaysPopupContentType
} from '../../../../interfaces/loubiairways';

const addClassName = (
  el: React.MutableRefObject<HTMLElement>,
  className: string[] | string,
  delay?: number
) => {
  const addClass = () => {
    typeof className === 'string'
      ? el?.current?.classList?.add(className)
      : el?.current?.classList?.add(...className);
  };

  if (delay) {
    setTimeout(addClass, delay);
  } else {
    addClass();
  }
};

const removeClassName = (
  el: React.MutableRefObject<HTMLElement>,
  className: string[] | string,
  delay?: number
) => {
  const removeClass = () => {
    typeof className === 'string'
      ? el?.current?.classList?.remove(className)
      : el?.current?.classList?.remove(...className);
  };

  if (delay) {
    setTimeout(removeClass, delay);
  } else {
    removeClass();
  }
};

export const selectEl = (el: React.MutableRefObject<HTMLElement>) => ({
  addClass: (className: string | string[], delay?: number) => {
    if (
      !el?.current?.className?.includes?.(
        typeof className === 'string' ? className : className.join(' ')
      )
    ) {
      addClassName(el, className, delay);
    }

    return selectEl(el);
  },
  removeClass: (className: string | string[], delay?: number) => {
    if (
      el.current?.className?.includes?.(
        typeof className === 'string' ? className : className.join(' ')
      )
    ) {
      removeClassName(el, className, delay);
    }

    return selectEl(el);
  }
});

export const is2DAnimation = (animation: LoubiAirwaysAnimation) => {
  return [
    LoubiAirwaysAnimation.IN_FLIGHT_MAGAZINE,
    LoubiAirwaysAnimation.NEWSPAPER,
    LoubiAirwaysAnimation.OMG_MAGAZINE,
    LoubiAirwaysAnimation.STYLISH_GUIDE,
    LoubiAirwaysAnimation.TRAY_TABLE,
    LoubiAirwaysAnimation.TROLLEY,
    LoubiAirwaysAnimation.COMPARTMENT_XRAY
  ].includes(animation);
};

export const animationVoyageThrottleDurationInMs = 50;

export const getPopupContentTypeByAnimation = (
  animation: LoubiAirwaysAnimation
): LoubiAirwaysPopupContentType | undefined => {
  switch (animation) {
    case LoubiAirwaysAnimation.LUGGAGE:
      return LoubiAirwaysPopupContentType.LUGGAGE;
    case LoubiAirwaysAnimation.TAROT_CARD:
      return LoubiAirwaysPopupContentType.TAROT;
    case LoubiAirwaysAnimation.COMPARTMENT_XRAY:
      return LoubiAirwaysPopupContentType.XRAY;
    case LoubiAirwaysAnimation.STYLISH_GUIDE:
      return LoubiAirwaysPopupContentType.SAFETY_GUIDE;
    case LoubiAirwaysAnimation.AIRPLANE_WINDOW:
      return LoubiAirwaysPopupContentType.AIRPLANE_WINDOW;
    case LoubiAirwaysAnimation.TROLLEY:
      return LoubiAirwaysPopupContentType.TROLLEY;
    case LoubiAirwaysAnimation.IN_FLIGHT_MAGAZINE:
      return LoubiAirwaysPopupContentType.IN_FLIGHT_MAGAZINE;
    default:
      return;
  }
};
