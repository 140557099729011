import LoubiButtonWrapper from './LoubiButtonWrapper';

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <LoubiButtonWrapper>
      <img
        src="/asset/louboutin/close.png"
        alt="close"
        onClick={onClick}
        className="loubi-circle-button top-right"
      />
    </LoubiButtonWrapper>
  );
};

export default CloseButton;
