import { zIndex } from '../../../config';
import { MeetingLayoutSpec } from '../../../interfaces';
import { SMGlobalSpec } from './SMGlobal';

export const SMPortraitNormalSpec: MeetingLayoutSpec = {
  thumbnailParticipant: { width: 180, height: 103 },
  thumbnailSelf: { width: 90, height: 60 },
  thumbnailRadius: 5,
  thumbnailSpacing: 3,
  get videosWrapper() {
    return {
      height: this.thumbnailParticipant.height + this.thumbnailSpacing * 4
    };
  },
  get contentArea() {
    return {
      bottom: this.videosWrapper.height,
      top: SMGlobalSpec.logoSectionHeight
    };
  },
  get participantWrapper() {
    return {
      width: this.thumbnailParticipant.width + this.thumbnailSpacing * 2,
      height: this.thumbnailParticipant.height + this.thumbnailSpacing * 2
    };
  }
};

const {
  thumbnailParticipant,
  thumbnailSelf,
  thumbnailRadius,
  thumbnailSpacing,
  videosWrapper,
  contentArea,
  participantWrapper
} = SMPortraitNormalSpec;

const presentationParticipantSize =
  thumbnailParticipant.height / 2 - thumbnailSpacing;

const presentationPresenterSize = {
  height: thumbnailParticipant.height / 2 - thumbnailSpacing,
  width: thumbnailParticipant.width / 2 - thumbnailSpacing
};

const SMPortraitNormal = ({
  totalPresenters,
  totalVideos
}: {
  totalPresenters?: number;
  totalVideos?: number;
}) => {
  const participantsWrapperWidth = !totalPresenters
    ? 'auto'
    : `${
        Math.ceil((totalVideos - totalPresenters - 1) / 2) *
        (presentationParticipantSize + thumbnailSpacing * 2)
      }px`;
  return (
    <>
      <style jsx global>{`
        .normal-mode.SMPortrait .video-thumbnail {
          border-radius: ${thumbnailRadius}px;
          margin: ${thumbnailSpacing}px;
        }

        .normal-mode.SMPortrait .video-thumbnail video {
          border-radius: ${thumbnailRadius}px;
        }

        .normal-mode.SMPortrait #videos-wrapper {
          position: fixed;
          left: 0;
          right: 0;
          top: auto;
          overflow: auto;
          bottom: 0;
          height: ${videosWrapper.height}px;
          display: flex;
          align-items: center;
        }

        .normal-mode.SMPortrait #videos-wrapper .video-thumbnail {
          width: ${thumbnailParticipant.width}px;
          height: ${thumbnailParticipant.height}px;
        }

        .normal-mode.SMPortrait .scroll-container {
          white-space: nowrap;
          display: block;
          font-size: 0;
          margin: auto;
        }

        .normal-mode.SMPortrait #videos-wrapper > .scroll-container {
          padding: 0 ${thumbnailSpacing}px;
        }

        .normal-mode.SMPortrait #local-wrapper .local-video-container {
          width: ${thumbnailSelf.width}px;
          height: ${thumbnailSelf.height}px;
          margin: 0;
        }

        .normal-mode.SMPortrait #local-wrapper {
          position: fixed;
          top: ${contentArea.top + thumbnailSpacing}px;
          left: ${thumbnailSpacing}px;
          width: ${thumbnailSelf.width}px;
          height: ${thumbnailSelf.height}px;
          z-index: ${zIndex.meetingVideosWrapper};
        }

        .normal-mode.SMPortrait.has-popup #local-wrapper {
          pointer-events: none;
          opacity: 0.01;
        }

        .normal-mode.SMPortrait .vb-page-wrapper,
        .normal-mode.SMPortrait .popup-page,
        .normal-mode.SMPortrait .popup-page-content,
        .normal-mode.SMPortrait .SBDocumentViewer {
          bottom: ${contentArea.bottom}px !important;
          top: ${contentArea.top}px !important;
          left: 0 !important;
          right: 0 !important;
        }

        .normal-mode.SMPortrait .SBDocumentViewer {
          width: 100% !important;
          height: calc(
            100% - ${(contentArea.top || 0) + (contentArea.bottom || 0)}px
          ) !important;
        }

        .normal-mode.SMPortrait .popup-page-content {
          border-radius: 0;
        }

        .normal-mode.SMPortrait .enlarge-video .video-thumbnail {
          top: ${contentArea.top}px !important;
          bottom: ${contentArea.bottom}px !important;
        }

        .normal-mode.SMPortrait .meeting-controls {
          bottom: ${videosWrapper.height + 10}px;
        }

        .normal-mode.SMPortrait .video-wrapper-bg {
          top: auto;
          height: ${videosWrapper.height}px;
        }

        .has-presenter.normal-mode.SMPortrait
          #participant-wrapper
          .video-thumbnail {
          width: ${presentationParticipantSize}px;
          height: ${presentationParticipantSize}px;
        }

        .has-presenter.normal-mode.SMPortrait
          #presenter-wrapper
          .video-thumbnail {
          width: ${presentationPresenterSize.width}px;
          height: ${presentationPresenterSize.height}px;
          margin: auto;
        }
        .has-presenter.single-presenter.normal-mode.SMPortrait
          #presenter-wrapper
          .video-thumbnail {
          width: ${thumbnailParticipant.width}px;
          height: ${thumbnailParticipant.height}px;
        }

        .has-presenter.normal-mode.SMPortrait #participant-wrapper,
        .has-presenter.normal-mode.SMPortrait #presenter-wrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          height: ${participantWrapper.height}px;
        }
        .has-presenter.normal-mode.SMPortrait #participant-wrapper {
          width: ${participantsWrapperWidth};
        }
        .has-presenter.normal-mode.SMPortrait
          #participant-wrapper
          .display-name {
          display: none;
        }
        .has-presenter.normal-mode.SMPortrait
          #videos-wrapper
          > .scroll-container {
          display: flex;
          align-items: center;
        }

        .normal-mode.SMPortrait .video-content-area {
          top: ${contentArea.top}px !important;
          bottom: ${contentArea.bottom}px !important;
        }

        .normal-mode.SMPortrait .one-third-wrapper {
          top: ${contentArea.top}px !important;
          left: ${contentArea.left || 0}px !important;
          bottom: ${contentArea.bottom}px !important;
          flex-direction: column;
        }
        .normal-mode.SMPortrait.one-third
          .one-third-wrapper
          .presenter
          .video-thumbnail
          video {
          object-fit: contain !important;
        }
        .normal-mode.SMPortrait {
          --onethird-left: 0px;
          --temp: calc(100vh - ${contentArea.top}px - ${contentArea.bottom}px);
          --onethird-top: calc(var(--temp) * 0.33333 + ${contentArea.top}px);
        }

        .normal-mode.SMPortrait.one-third .SBDocumentViewer {
          width: unset !important;
          height: unset !important;
        }
        .normal-mode.one-third.SMPortrait .popup-page-content,
        .normal-mode.one-third.SMPortrait .popup-page,
        .normal-mode.one-third.SMPortrait .SBDocumentViewer {
          top: var(--onethird-top) !important;
          bottom: ${contentArea.bottom} !important;
        }

        /* END: NORMAL MODE SMALL SCREEN PORTRAIT */
      `}</style>
    </>
  );
};

export default SMPortraitNormal;
