import React from 'react';
import { MDGlobalSpec } from '../../../../Meeting/MeetingLayout/MDGlobal';
import { SMGlobalSpec } from '../../../../Meeting/MeetingLayout/SMGlobal';

const baseHeight = 70;
const spacing = 10;
const mobileSpacing = 7;
const mobileHeight = baseHeight * 0.8;
const LoubiButtonWrapper = ({ children }: { children: React.ReactNode }) => (
  <>
    {children}
    <style jsx global>{`
      .loubi-circle-button {
        position: fixed;
        height: ${baseHeight}px;
        width: auto;
        cursor: pointer;
        user-select: none;
        filter: drop-shadow(0 0 2px #4d0006);
      }
      .loubi-circle-button.top-left {
        top: ${spacing}px;
        left: ${spacing}px;
      }
      .loubi-circle-button.top-center {
        top: ${spacing}px;
        left: 50%;
        margin-left: -${baseHeight / 2}px;
      }
      .loubi-circle-button.top-right {
        top: ${spacing}px;
        right: ${spacing}px;
      }
      .in-meeting.SM .loubi-circle-button.top-right{
        top: ${SMGlobalSpec.logoSectionHeight + spacing}px;
      }
      .in-meeting.MD .loubi-circle-button.top-right{
        top: ${MDGlobalSpec.logoSectionHeight + spacing}px;
      }
      .loubi-circle-button.top-second-right {
        top: ${baseHeight + spacing * 2}px;
        right: ${spacing}px;
      }

      .in-meeting.SM .loubi-circle-button.top-second-right{
        top: ${SMGlobalSpec.logoSectionHeight + baseHeight + spacing * 2}px;
      }
      .in-meeting.MD .loubi-circle-button.top-second-right{
        top: ${MDGlobalSpec.logoSectionHeight + baseHeight + spacing * 2}px;
      }

      .loubi-circle-button.top-third-right {
        top: ${baseHeight * 2 + spacing * 3}px;
        right: ${spacing}px;
      }

      .in-meeting.SM .loubi-circle-button.top-third-right{
        top: ${SMGlobalSpec.logoSectionHeight + baseHeight * 2 + spacing * 3}px;
      }
      .in-meeting.MD .loubi-circle-button.top-third-right{
        top: ${MDGlobalSpec.logoSectionHeight + baseHeight * 2 + spacing * 3}px;
      }

      .loubi-circle-button.bottom-left {
        bottom: ${spacing}px;
        left: ${spacing}px;
      }
      .loubi-circle-button.bottom-right {
        bottom: ${spacing}px;
        right: ${spacing}px;
      }

      .loubi-circle-button.bottom-second-right {
        bottom: ${baseHeight + spacing * 2}px;
        right: ${spacing}px;
      }

      .loubi-circle-button.bottom-third-right {
        bottom: ${baseHeight * 2 + spacing * 3}px;
        right: ${spacing}px;
      }

      .loubi-circle-button.bottom-fourth-right {
        bottom: ${baseHeight * 3 + spacing * 4}px;
        right: ${spacing}px;
      }

      @media (max-width: 768px) {
        .loubi-circle-button {
          height: ${mobileHeight}px;
        }
        .loubi-circle-button.top-left {
          top: ${mobileSpacing}px;
          left: ${mobileSpacing}px;
        }
        .loubi-circle-button.top-center {
          top: ${mobileSpacing}px;
          margin-left: -${mobileHeight / 2}px;
        }
        .loubi-circle-button.top-right {
          top: ${mobileSpacing}px;
          right: ${mobileSpacing}px;
        }
        .loubi-circle-button.top-second-right {
          top: ${mobileHeight + mobileSpacing * 2}px;
          right: ${mobileSpacing}px;
        }

        .loubi-circle-button.top-third-right {
          top: ${mobileHeight * 2 + mobileSpacing * 3}px;
          right: ${mobileSpacing}px;
        }

        .loubi-circle-button.bottom-left {
          bottom: ${mobileSpacing}px;
          left: ${mobileSpacing}px;
        }
        .loubi-circle-button.bottom-right {
          bottom: ${mobileSpacing}px;
          right: ${mobileSpacing}px;
        }

        .loubi-circle-button.bottom-right.spotify {
          bottom: ${mobileSpacing + 40}px;
        }
        .loubi-circle-button.bottom-second-right {
          bottom: ${mobileHeight + mobileSpacing * 2}px;
          right: ${mobileSpacing}px;
        }
        .loubi-circle-button.bottom-second-right.spotify {
          bottom: ${mobileHeight + mobileSpacing * 2 + 40}px;
        }
        .loubi-circle-button.bottom-third-right {
          bottom: ${mobileHeight * 2 + mobileSpacing * 3}px;
          right: ${mobileSpacing}px;
        }
        .loubi-circle-button.bottom-third-right.spotify {
          bottom: ${mobileHeight * 2 + mobileSpacing * 3 + 40}px;
        }
        .loubi-circle-button.bottom-fourth-right.spotify {
          bottom: ${mobileHeight * 3 + mobileSpacing * 4 + 40}px;
        }
        .loubi-circle-button.bottom-fourth-right {
          bottom: ${mobileHeight * 3 + mobileSpacing * 4}px;
          right: ${mobileSpacing}px;
        }
      }
    `}</style>
  </>
);

export default LoubiButtonWrapper;
