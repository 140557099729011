import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { useTranslation } from '../../../../../i18n';
import { IAnimationProduct } from '../../../../../interfaces/loubiairways';
import {
  actionAddToWishlist,
  actionRemoveFromWishlist
} from '../../../../../redux/customActions/loubiAirways';
import {
  DID_ADD_TO_WISHLIST,
  DID_REMOVE_FROM_WISHLIST
} from '../../../../../utils/constants';
import { LoubiAirFontFamily } from '../../../../BrandStyle/LouboutinStyle';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';

const LoubiAirwaysWishlist = ({ product }: { product: IAnimationProduct }) => {
  const wishlist = useLoubiAirwaysState()?.wishlist?.wishlistItems || [];
  const { t } = useTranslation();
  const loubiFont = LoubiAirFontFamily();
  const dispatch = useDispatch();
  const alreadyInWishlist = wishlist.some((p) => p.itemId === product.id);
  return (
    <>
      <div
        className={`favorite-button add-to-wishlist ${
          alreadyInWishlist ? 'hidden' : ''
        }`}
        onClick={() => {
          dispatch(actionAddToWishlist(product));
          logEvent(DID_ADD_TO_WISHLIST, DID_ADD_TO_WISHLIST, {
            id: product.id,
            modelCode: product.modelCode,
            modelName: product.modelName
          });
        }}
      >
        <img src="/asset/louboutin/wishlist_empty.svg" alt="Add to Wishlist" />
        <p>{t('vb:add_to_favourites')}</p>
      </div>
      <div
        className={`favorite-button remove-from-wishlist ${
          !alreadyInWishlist ? 'hidden' : ''
        }`}
        onClick={() => {
          dispatch(actionRemoveFromWishlist(product));
          logEvent(DID_REMOVE_FROM_WISHLIST, DID_REMOVE_FROM_WISHLIST, {
            id: product.id,
            modelCode: product.modelCode,
            modelName: product.modelName
          });
        }}
      >
        <img
          src="/asset/louboutin/wishlist_filled.svg"
          alt="Remove from Wishlist"
        />
        <p className="red">{t('vb:added_to_favourites')}</p>
      </div>

      <style jsx>{`
        .favorite-button {
          position: fixed;
          top: 38px;
          left: 50%;
          cursor: pointer;
          transform: translateX(-50%);
          z-index: 3;
          text-align: center;
        }
        .favorite-button.hidden {
          z-index: 2;
          opacity: 0.01;
        }
        .favorite-button.hidden p {
          display: none;
        }
        p {
          color: #fff;
          font-family: ${loubiFont};
          font-size: 0.7em;
          margin-top: 5px;
        }
        .red {
          color: #cf152d;
        }
        img {
          width: 72px;
          height: auto;
        }
        .favorite-button:active {
          transform: translate(-50%, 10%);
        }
        @media (max-width: 768px) {
          .favorite-button {
            top: 20px;
          }
        }
      `}</style>
    </>
  );
};

export default LoubiAirwaysWishlist;
