import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { IMainState } from '../../interfaces';
import { LoubiAirwaysAnimation } from '../../interfaces/loubiairways';

export const useRemoteLoubiAirwaysAnimationState = (
  animation: LoubiAirwaysAnimation
) => {
  const remoteState = useSelector(
    (state: IMainState) =>
      (state.clientState.loubiAirways?.remoteAnimation || {})[animation]
  );

  return remoteState;
};

export const useLoubiAirwaysState = () =>
  useSelector((state: IMainState) => state.clientState.loubiAirways || {});

export const isUserLoubiAirwaysVIP = () => {
  const router = useRouter();
  return router.query.wishlist === 'true';
};

export const shouldEnableLikeAndReservationForLoubiJp = () => {
  const invite = useSelector(
    (state: IMainState) => state.clientState.loubiAirways?.invite
  );
  const router = useRouter();
  const meetingId = router.query.meeting;
  const isInMeeting = !!meetingId;
  return !!invite || isInMeeting;
};
