import { uniqBy } from 'lodash';
import { assetBaseUrl, louboutinBrandId } from '../../../../../config';
import { IAnimationProduct } from '../../../../../interfaces/loubiairways';
import { all3DProducts } from '../../../../ThreejsAnimation/louboutin/assets';

export enum AnimationProductModel {
  CLLOUBIAIRWAYS004 = 'CLLOUBIAIRWAYS004',
  CLLOUBIAIRWAYS008 = 'CLLOUBIAIRWAYS008',
  CLLOUBIAIRWAYS009 = 'CLLOUBIAIRWAYS009',
  CLLOUBIAIRWAYS012 = 'CLLOUBIAIRWAYS012',
  CLLOUBIAIRWAYS013 = 'CLLOUBIAIRWAYS013',
  CLLOUBIAIRWAYS018 = 'CLLOUBIAIRWAYS018',
  CLLOUBIAIRWAYS020 = 'CLLOUBIAIRWAYS020',
  CLLOUBIAIRWAYS022 = 'CLLOUBIAIRWAYS022',
  CLLOUBIAIRWAYS023 = 'CLLOUBIAIRWAYS023',
  CLLOUBIAIRWAYS024 = 'CLLOUBIAIRWAYS024',
  CLLOUBIAIRWAYS025 = 'CLLOUBIAIRWAYS025',
  CLLOUBIAIRWAYS026 = 'CLLOUBIAIRWAYS026',
  CLLOUBIAIRWAYS027 = 'CLLOUBIAIRWAYS027',
  CLLOUBIAIRWAYS028 = 'CLLOUBIAIRWAYS028',
  CLLOUBIAIRWAYS035 = 'CLLOUBIAIRWAYS035',
  CLLOUBIAIRWAYS036 = 'CLLOUBIAIRWAYS036',
  CLLOUBIAIRWAYS037 = 'CLLOUBIAIRWAYS037',
  CLLOUBIAIRWAYS038 = 'CLLOUBIAIRWAYS038',
  CLLOUBIAIRWAYS039 = 'CLLOUBIAIRWAYS039',
  CLLOUBIAIRWAYS040 = 'CLLOUBIAIRWAYS040',
  CLLOUBIAIRWAYS041 = 'CLLOUBIAIRWAYS041',
  CLLOUBIAIRWAYS042 = 'CLLOUBIAIRWAYS042',
  CLLOUBIAIRWAYS043 = 'CLLOUBIAIRWAYS043',
  CLLOUBIAIRWAYS044 = 'CLLOUBIAIRWAYS044',
  CLLOUBIAIRWAYS045 = 'CLLOUBIAIRWAYS045',
  CLLOUBIAIRWAYS046 = 'CLLOUBIAIRWAYS046',
  CLLOUBIAIRWAYS047 = 'CLLOUBIAIRWAYS047',
  CLLOUBIAIRWAYS048 = 'CLLOUBIAIRWAYS048',
  CLLOUBIAIRWAYS049 = 'CLLOUBIAIRWAYS049',
  CLLOUBIAIRWAYS050 = 'CLLOUBIAIRWAYS050',
  CLLOUBIAIRWAYS051 = 'CLLOUBIAIRWAYS051',
  CLLOUBIAIRWAYS052 = 'CLLOUBIAIRWAYS052',
  CLLOUBIAIRWAYS053 = 'CLLOUBIAIRWAYS053',
  CLLOUBIAIRWAYS115 = 'CLLOUBIAIRWAYS115',
  CLLOUBIAIRWAYS116 = 'CLLOUBIAIRWAYS116',
  CLLOUBIAIRWAYS117 = 'CLLOUBIAIRWAYS117',
  CLLOUBIAIRWAYS118 = 'CLLOUBIAIRWAYS118',
  CLLOUBIAIRWAYS119 = 'CLLOUBIAIRWAYS119',
  CLLOUBIAIRWAYS120 = 'CLLOUBIAIRWAYS120',
  CLLOUBIAIRWAYS121 = 'CLLOUBIAIRWAYS121',
  CLLOUBIAIRWAYS122 = 'CLLOUBIAIRWAYS122',
  CLLOUBIAIRWAYS123 = 'CLLOUBIAIRWAYS123'
}

const animation2DProducts: IAnimationProduct[] = [
  {
    id: '7c04a60d-8fe2-4901-81df-8d344697dab9',
    modelName: 'LOOPINGA TOE PLUME',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS004
  },

  {
    id: 'a33deace-927a-4d81-962e-605eaec77956',
    modelName: 'PALOMA BAGUETTE SMALL',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS008
  },
  {
    id: 'a3711ec9-c7ff-4b94-94c4-a922637023c0',
    modelName: 'OFFICIALITO',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS009
  },

  {
    id: 'c12c303b-845a-4444-9de3-67f249c3e228',
    modelName: 'SANDALE DU DESERT',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS012
  },
  {
    id: '8b0ca156-1bac-40be-b46b-06f2a9e5f459',
    modelName: 'CARACABA SMALL',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS013
  },

  {
    id: 'efcea76c-7aab-4ac9-8f26-a107c00b8479',
    modelName: 'RANTULOW ORLATO',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS018
  },

  {
    id: '96d5b717-2e99-42f4-b5ed-fd406a4bd19e',
    modelName: 'NAVY COOLITO',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS020
  },

  {
    id: '0f83e769-60d6-4e82-8065-9ae3d7b56874',
    modelName: 'MELON SPIKES',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS022
  },
  {
    id: 'dad27dc6-9354-4ce2-ae4e-67b9e7e9cf25',
    modelName: 'BLASTER CROSSBODY',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS023
  },
  {
    id: 'c76026c3-0fa3-4272-a28e-89e3da5281fb',
    modelName: 'ELISA BAGUETTE S',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS024
  },
  {
    id: 'd34d9fda-aa2e-47d0-9154-ede8e6a5fa33',
    modelName: 'ELISA BAGUETTE S',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS025
  },
  {
    id: 'a23b2cb1-7ecf-4ca3-98cb-087dd8e29f41',
    modelName: 'ELISA BRACELET DOUBLE 15',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS026
  },
  {
    id: '618e7904-ac0c-48c2-8722-c86fc39a7509',
    modelName: 'ELISA BRACELET DOUBLE 15',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS027
  },
  {
    id: '5242088d-d731-4c13-a1c6-bd30a1909892',
    modelName: 'W CL X PEACE KEYRING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS028
  },

  {
    id: '620fa63f-2566-4ea1-8e20-d36371c787f2',
    modelName: 'BLASTER CROSSBODY',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS035
  },
  {
    id: '9deba2a5-c7c9-453a-a4b3-01b46df59f0f',
    modelName: 'LOCK WOODY',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS036
  },
  {
    id: '038e9594-d286-4f46-8ba4-4a6b348396b2',
    modelName: 'VELCRISSIMO',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS037
  },
  {
    id: '5647066b-82b7-4892-8acb-0c5da028e17a',
    modelName: 'PATROUIAGOMA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS038
  },
  {
    id: '2b0e4083-f5ed-4a44-af00-f0241f8765ec',
    modelName: 'DAME ANNE',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS039
  },
  {
    id: '077b0781-89c5-4c3a-ba3b-a2aacddb4bf9',
    modelName: 'SUMMER LOUBISHARK',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS040
  },
  {
    id: 'cf7d04a9-90c0-4f49-9244-1b10fb6495fe',
    modelName: 'HINDOLUG',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS041
  },
  {
    id: '40462a91-c2ff-45eb-8bcd-c5489fb754bd',
    modelName: 'LOUBISHARK',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS042
  },
  {
    id: 'f84b1421-6c45-4252-96dd-b1d1ec065540',
    modelName: 'FUN LOUIS',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS043
  },
  {
    id: '294067ce-5b16-4e3e-95a0-95e40c86bd49',
    modelName: 'COOLITO SWING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS044
  },
  {
    id: '18fe4d31-e9bf-49fb-9ce3-322a160b3025',
    modelName: 'COOLITO SWING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS045
  },
  {
    id: '69ebd5db-1955-44fe-900f-3e7c11e8484c',
    modelName: 'MAYERSWING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS046
  },
  {
    id: '4cd29464-f9ad-4eee-a48d-f74890902838',
    modelName: 'COOLITO SWING DONNA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS047
  },
  {
    id: '460f02e5-eacc-469a-8a2b-344577fd34df',
    modelName: 'CHARLETA SWING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS048
  },
  {
    id: '90fa6060-f3e2-497a-bcdf-2ea26b7e3f38',
    modelName: 'MAYERSWING DONNA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS049
  },
  {
    id: '1bbbbabd-5519-47d8-a113-8a515bb9112b',
    modelName: 'AQUARIA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS050
  },
  {
    id: '5c0b8e6b-c453-4ba4-9bcb-6a55766ed5d9',
    modelName: 'AQUALLIA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS051
  },
  {
    id: '67031488-e166-4e8b-895f-f240830910b6',
    modelName: 'AQUAJANE',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS052
  },
  {
    id: 'e48207fc-e302-4697-b5bf-94921cef49eb',
    modelName: 'AQUARIA BOTTA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS053
  },
  {
    id: '25e00768-43f5-4a6e-85ce-f3c96ca727c4',
    modelName: 'KATE SLING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS121
  },
  {
    id: '79094088-08ce-4a53-9360-f551be94862e',
    modelName: 'FUN LOUIS JUNIOR SPIKE',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS115
  },
  {
    id: '9a57b50c-76e7-4c01-9bfb-341ba6f967d5',
    modelName: 'FUN VIEIRA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS116
  },
  {
    id: '7f8bc3c4-bc9a-4865-8fef-aa149aa55fe1',
    modelName: 'MOCALAUREAT',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS117
  },
  {
    id: '2eac1b27-c0b8-436c-a669-614d52c94846',
    modelName: 'SO KATE',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS118
  },
  {
    id: '2fe34681-de5f-4b9c-8d8b-4ccb44da157d',
    modelName: 'VERNICA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS119
  },
  {
    id: 'fef591af-4d7c-4d1a-9551-0f3e3b1f9c10',
    modelName: 'PALOMA BAGUETTE SMALL',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS120
  },
  {
    id: '25e00768-43f5-4a6e-85ce-f3c96ca727c4',
    modelName: 'KATE SLING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS121
  },
  {
    id: '35a58dd9-839e-4d27-9bed-37891e07d107',
    modelName: 'MONTEZU LUG',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS122
  },
  {
    id: '49d0a5f0-5265-4313-bfc6-5054bf66e565',
    modelName: 'MORSETTO BRACELET DOUBLE 10',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS123
  }
];

const allProducts = uniqBy([...animation2DProducts, ...all3DProducts], 'id');
export const get2DAnimationProductByModel = (
  modelCode: string
): IAnimationProduct =>
  animation2DProducts.find((p) => p.modelCode === modelCode);

export const getLoubiAirwaysProductById = (id: string): IAnimationProduct =>
  allProducts.find((p) => p.id === id);

export const get2DProductImage = (model: IAnimationProduct) => {
  const product = animation2DProducts.find((p) => p.id === model?.id);
  if (!product) {
    return getLoubiAirwaysProductImage(model);
  }
  return `${assetBaseUrl}/loubiairways/luggage-animation/products-2d/v12/${product?.modelCode}.png`;
};

export const getLoubiAirwaysProductImage = (model: IAnimationProduct) => {
  return `https://products.inspify.io/${louboutinBrandId}%2F${model?.id}%2F1000x1000%2F${model?.id}_001.png`;
};
