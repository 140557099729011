import React from 'react';
import { throttle } from 'lodash';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import {
  LoubiAirwaysAnimation,
  REMOTE_ACTION
} from '../../../../../interfaces/loubiairways';
import { getViewportSize } from '../../../../../utils/window';
import FlipBoardText, { FlipBoardTextTheme } from '../FlipBoardText';
import {
  get2DProductImage,
  getLoubiAirwaysProductImage
} from './TwoDProductsList';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { DID_UPDATE_2D_PRODUCT_IMAGE_STATE } from './../../../../../utils/constants';
import { IMainState } from '../../../../../interfaces';
import { actionUpdateLoubiAirwaysSelectedModel } from '../../../../../redux/customActions/loubiAirways';
import { animationVoyageThrottleDurationInMs } from '../animationUtils';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import BackToAnimationButton from '../Buttons/BackToAnimationButton';
import CloseButton from '../Buttons/CloseButton';
import { MDLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/SMLandscapeNormal';

interface ZoomAndPosition {
  previousScale?: number;
  scale?: number;
  positionX?: number;
  positionY?: number;
}
const TwoDProductImageContainer = ({
  useBackendImage
}: {
  useBackendImage?: boolean;
}) => {
  const dispatch = useDispatch();

  const [show, setShow] = React.useState(false);
  const [animateClose, setAnimateClose] = React.useState(false);
  const [img, setImg] = React.useState<string>();
  const [viewportSize, setViewportSize] = React.useState({ vw: 1, vh: 1 });
  const { vh, vw } = viewportSize;
  const isRemoteUser = !!useSelector(
    (state: IMainState) => state.clientState.loubiAirways?.remoteAnimation
  );

  const { selectedModel, activeAnimation } = useLoubiAirwaysState() || {};

  const handleClose = () => {
    dispatch(actionUpdateLoubiAirwaysSelectedModel(undefined));
  };

  const getShowDelayTime = () => {
    if (activeAnimation === LoubiAirwaysAnimation.STYLISH_GUIDE) return 400;
    if (activeAnimation === LoubiAirwaysAnimation.TRAY_TABLE) return 1000;
    return 0;
  };

  const logEventThrottled = throttle(
    (state: ZoomAndPosition) => {
      logEvent(
        DID_UPDATE_2D_PRODUCT_IMAGE_STATE,
        DID_UPDATE_2D_PRODUCT_IMAGE_STATE,
        {
          model: selectedModel?.model,
          state
        }
      );
    },
    1000,
    { trailing: true }
  );

  const throttledUpdateState = throttle((state: ZoomAndPosition) => {
    dispatch(
      actionUpdateLoubiAirwaysSelectedModel({
        ...selectedModel,
        scale: [state?.previousScale || 1, state?.scale || 1],
        position: [state?.positionX || null, state?.positionY || null]
      })
    );
  }, animationVoyageThrottleDurationInMs);

  const updateState = (state: ZoomAndPosition) => {
    throttledUpdateState(state);
  };

  const onZoomChange = (e: any) => {
    const state = {
      previousScale: e?.previousScale,
      scale: e?.scale,
      positionX: e?.positionX / vw,
      positionY: e?.positionY / vh
    };

    if (
      state?.scale !== selectedModel?.scale?.[1] &&
      !isRemoteUser &&
      state?.scale >= 1
    ) {
      updateState(state);
    }

    if (selectedModel) {
      logEventThrottled(state);
    }
  };

  const positionX = (selectedModel?.position?.[0] || 0) * vw;

  const positionY = (selectedModel?.position?.[1] || 0) * vh;

  React.useEffect(() => {
    setViewportSize(getViewportSize());
  }, []);

  React.useEffect(() => {
    if (selectedModel?.state === REMOTE_ACTION.OPEN) {
      setImg(
        useBackendImage
          ? getLoubiAirwaysProductImage(selectedModel?.model)
          : get2DProductImage(selectedModel?.model)
      );
      if (
        activeAnimation === LoubiAirwaysAnimation.STYLISH_GUIDE ||
        activeAnimation === LoubiAirwaysAnimation.TRAY_TABLE
      ) {
        setTimeout(() => {
          setShow(true);
        }, getShowDelayTime());
      } else {
        setShow(true);
      }
    } else {
      setAnimateClose(true);
      setTimeout(() => {
        if (show) {
          setShow(false);
        }
        setAnimateClose(false);
        if (img) {
          setImg(undefined);
        }
      }, 300);
    }
  }, [selectedModel?.state]);

  if (!img || !show) return null;

  return (
    <div className={`twod-product fixed-full ${animateClose ? 'closing' : ''}`}>
      <div className="backdrop fixed-full" />
      <div className="letter-box-container">
        <FlipBoardText
          text={selectedModel?.model?.modelName}
          theme={FlipBoardTextTheme.RED}
        />
      </div>
      <TransformWrapper
        wheel={{ step: 100 }}
        pan={{ lockAxisX: true, lockAxisY: true }}
        scale={selectedModel?.scale?.[1]}
        positionX={positionX}
        positionY={positionY}
        options={{ disabled: isRemoteUser, maxScale: 4 }}
        doubleClick={{ disabled: true }}
        onZoomChange={onZoomChange}
      >
        <TransformComponent>
          <div className="img-container">
            <div className="veil-close" onClick={handleClose} />
            <img src={img} />
          </div>
        </TransformComponent>
      </TransformWrapper>
      {activeAnimation !== LoubiAirwaysAnimation.TROLLEY && (
        <BackToAnimationButton onClick={handleClose} />
      )}
      {!activeAnimation && <CloseButton onClick={handleClose} />}
      <style jsx>{`
        .twod-product {
          animation: fade-in 0.3s linear both;
        }
        .twod-product.closing {
          animation: fade-out 0.3s linear both;
        }
        .veil-close {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 0;
        }

        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        .twod-product img {
          margin: auto;
          position: relative;
          z-index: 1;
          height: 80vh;
          max-height: 100vw;
          width: auto;
        }

        :global(.in-meeting.MDLandscape) .twod-product img {
          max-height: calc(100vw - ${MDLandscapeNormalSpec.contentArea.left}px);
        }
        :global(.in-meeting.SMLandscape) .twod-product img {
          max-height: calc(100vw - ${SMLandscapeNormalSpec.contentArea.left}px);
        }

        .img-container {
          width: 100vw;
          height: ${getViewportSize().vh}px;
          text-align: center;
          position: relative;
          z-index: 1;
          display: flex;
        }
        :global(.in-meeting.MDLandscape) .twod-product .img-container {
          width: calc(100vw - ${MDLandscapeNormalSpec.contentArea.left}px);
        }
        :global(.in-meeting.SMLandscape) .twod-product .img-container {
          width: calc(100vw - ${SMLandscapeNormalSpec.contentArea.left}px);
        }
        .letter-box-container {
          z-index: 0;
          margin-left:0 !important;
        }
        .backdrop {
          background: black;
          opacity: 0.7;
          z-index: 0;
        }
      `}</style>
    </div>
  );
};

export default TwoDProductImageContainer;
